import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import './Performer.scss'
import { CircularProgress } from '@material-ui/core';

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: null,
      severity: null,
      isFileLoading: false,
      isupload: false,
      isDelete: null,
      fileList: []
    }

  }

  componentDidMount = () => {
    this.getFiles()
  }

  getFiles() {
    this.setState({ isFileLoading: true })
    let getJson = { "objectType": "PerformerDeal", "fileCategory": "files", "performerDealId": this.props?.performerDetails?.performer_deal_id, "projectId": this.props?.project_id, "signedURLType": "downloadFilesList" }
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureUpload`, getJson, null)
      .then((response) => {
        if (response?.data?.error) {
          this.setState({ openNotification: 'File load Fail', severity: 'error' })
        }
        else {
          this.setState({ isFileLoading: false, fileList: response.data.data })
        }
      },
        (err) => {
          this.setState({ openNotification: 'File load Fail', severity: 'error' })
        })
  }

  deleteFile = (fileName, loader) => {
    this.setState({ isDelete: loader })
    let deleteParams = { "objectType": "PerformerDeal", "fileCategory": "files","performerDealId": this.props?.performerDetails?.performer_deal_id, "filename": fileName, "projectId": this.props?.project_id }
    CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', deleteParams, null)
      .then(response => {
        if (response.data.error) {
          this.setState({ openNotification: response?.data?.message || 'File deletion failed', severity: 'error' })
          return
        }
        else {
          this.setState({ openNotification: 'File deleted successfully', severity: 'success', isDelete: null })
          this.getFiles()
        }

      }, (err) => {
        this.setState({ openNotification: 'File deletion failed', severity: 'error' })
      });
  }


  fileUpload = (file) => {
    let uploadFileParams = {
      "objectType": "PerformerDeal",
      "fileCategory": "files",
      "filename": file?.name,
      "projectId": this.props?.project_id,
      "signedURLType": "upload",
      "performerDealId": this.props?.performerDetails?.performer_deal_id,
    }
    this.setState({ isupload: true })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureUpload', uploadFileParams, null)
      .then(response => {
        if (response?.data?.error) {
          this.setState({ openNotification: response?.data?.message || 'File upload failed', severity: 'error', isupload: false })
          return
        }
        CasterService.uploadFile(response.data, file).then(res => {
          if (res?.data?.error) {
            this.setState({ openNotification: res?.data?.message || 'File upload failed', severity: 'error', isupload: false })
            return
          }
          this.getFiles()
          this.setState({ isupload: false, openNotification: 'File upload successfully', severity: 'success' })
        }, (err) => {
          this.setState({ openNotification: 'File upload failed', severity: 'error', isupload: false })
        });
      }, (err) => {
        this.setState({ openNotification: 'File upload failed', severity: 'error', isupload: false })
      });
  }

  downloadFile = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    return (
      <MDBContainer>
        <NotificationComponent
          open={this.state.openNotification ? true : false}
          message={this.state.openNotification || ""}
          severity={this.state.severity || ""}
          handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
        {this.state.isFileLoading ? <CircularProgress /> : <MDBRow className='mr-2'>
          {this.state.fileList?.map(item => {
            return <MDBCol md={4} style={{height: "30px"}}>
              <MDBRow>
                <MDBCol md={11}>
                  <span className='pointer grnColor' onClick={(e) => this.downloadFile(item?.url)}>{item?.Key}</span>
                </MDBCol>
                <MDBCol md={1}>
                  {this.state.isDelete === item?.id ? <CircularProgress color="inherit" size={20} /> : <MDBIcon onClick={(e) => this.deleteFile(item?.Key, item?.id)} className='pointer' icon={'times'}></MDBIcon>}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          })}
          <MDBCol md={4} style={{height: "30px"}}>
                <BasicButton
                  type="inline"
                  className="FR MR1"
                  variant="contained"
                  inputType="file"
                  id='casterFCPerforerFileSectionNew'
                  text={this.state.isupload ? <CircularProgress color="inherit" size={20} /> : "Add File"}
                  uploadFile={this.fileUpload.bind(this)}
                />
              </MDBCol>
        </MDBRow>}
      </MDBContainer>
    );
  }
}

export default Files;
