import React from 'react';
import { MDBRow } from "mdbreact";
import TableComponent from '../SharedComponents/Table';
import { BillingListConfig } from './config';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import TablePagination from '@material-ui/core/TablePagination';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import Popover from '@material-ui/core/Popover';
import './BillingSection.scss'
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { downloadUrl } from '../../Common/Helper';
import AlphabetAccentChars from '../SharedComponents/AlphabetVarPopover/AlphabetVariants.json';

class BillingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setAttPopover: null,
      billingRowData: null,
      allBillingData: [],
      allFilteredData: [],
      config: JSON.parse(JSON.stringify({ ...BillingListConfig })),
      isSortByAsc: true,
      sortCount: 0,
      sortBy: "",
      page: 0,
      rows: 100,
      isFetchingBillingData: false,
      billingNotes: null,
      openNotification: null,
      severity: null,
      renderList: [],
      isSubmit: null,
      file: null,
      dataItem: null,
      isRepl: 0,
      deletedSelectedFile: null,
      filterKeyValuePair: []
    }
  }

  componentDidMount() {
    this.getBilinginfo(true)
    this.getUnionData()
  }

  getBilinginfo(loader = false) {
    this.setState({ isFetchingBillingData: loader })
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealBilling?project_id=${this.props?.project_id}`).then((response) => {
      if (response?.data?.error) {
        this.setState({ openNotification: 'Billing Data Load Fail', severity: 'error' })
      }
      else {
        this.setState({
          allFilteredData: [], allBillingData: response.data,
          renderList: handlePaginationList(this.state.page, this.state.rows, response.data), isFetchingBillingData: false, isSubmit: false
        }, () => {
          if (this.state.filterValues?.length > 0) {
            this.applyFilterBillingList(this.state.filterValues, false);
          } else if (this.state.sortBy !== "") {
            this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
          }
        })
      }
    },
      (err) => {
        this.setState({ openNotification: 'Billing Data Load Fail', severity: 'error' })
      })
  }

  getUnionData = () => {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=UNION`)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, text: item.lookup_value }));
        let configData = JSON.parse(JSON.stringify(this.state.config))
        configData.headings[3].filterOptions = formattedList
        this.setState({ config: configData });
      },
        (err) => {
          console.log("Error in fetching Text:", err)
        })
  }

  saveInlineEdits = (data, index, pkValue, dataItem, notesFlag = 0) => {
    this.setState({ isSubmit: dataItem?.performer_deal_billing_id });
    (this.state?.file) ? (this.handleBillingFileSelect(this.state?.file, this.state?.dataItem, this.state?.isRepl, null)).then(() => { this.saveBillingData(data, index, pkValue, dataItem, notesFlag = 0) }) : '';
    (this.state?.deletedSelectedFile) ? this.deleteFile().then(() => { this.saveBillingData(data, index, pkValue, dataItem, notesFlag = 0) }) : '';
    (!this.state?.file && !this.state?.deletedSelectedFile) ? this.saveBillingData(data, index, pkValue, dataItem, notesFlag = 0) : '';

  }

  saveBillingData(data, index, pkValue, dataItem, notesFlag = 0) {
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealBilling`, notesFlag ? dataItem : data[index])
      .then(response => {
        if (response?.data?.error) {
          this.setState({ openNotification: response?.data?.message || 'Saving Changes Failed', severity: 'error', isSubmit: null }, () => {
            this.getBilinginfo(true);
          })
        } else {
          let updateModifiedData = JSON.parse(JSON.stringify(this.state.allBillingData));
          let modifiedIndex = updateModifiedData.findIndex(billingData => {
            return billingData?.performer_deal_billing_id === response?.data[0]?.performer_deal_billing_id
          })
          updateModifiedData[modifiedIndex] = response?.data[0];
          this.setState({
            allBillingData: updateModifiedData, openNotification: 'Changes saved successfully', severity: 'success',
            renderList: handlePaginationList(this.state.page, this.state.rows, updateModifiedData), isFetchingBillingData: false, isSubmit: false
          }, () => {
            if (this.state.filterValues?.length > 0) {
              this.applyFilterBillingList(this.state.filterValues, false);
            } else if (this.state.sortBy !== "") {
              this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
            }
          })
        }
      },
        (err) => {
          this.setState({ openNotification: 'Saving changes failed', severity: 'error', isSubmit: null }, () => {
            this.getBilinginfo(true);
          })
        }
      )
  }

  handleInlineEdits = (pkValue, dataItem) => {
    let billingData = this.state.renderList?.map(item => {
      let newItem = { ...item }
      if (item?.performer_deal_billing_id === pkValue) {
        newItem.editing = true
      } else {
        newItem.editing = false
      }
      return newItem
    })
    this.setState({ renderList: billingData })


  }

  convertToAscii(value) {
    const stringWithoutAccents = value.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
    return stringWithoutAccents.replace(/[^\u0000-\u007E]/g, character => {
      for (let key in AlphabetAccentChars) {
        if (AlphabetAccentChars[key].indexOf(character) >= 0) {
          return key || '';
        }
      }
    });
  }


  applyFilterBillingList = (filterValues, filtersNone) => {
    var finalResult = [];
    let filterKeyValuePair = [];
    var name; var value;
    if (!filtersNone) {
      filterValues.map((filter) => {
        var keyValue = filter.toString().split(":");
        name = keyValue[0].trim();
        value = keyValue[1].trim().toLowerCase();
        filterKeyValuePair.push({ [name]: value });
        var index = -1;
        Object.keys(this.state.config?.headings).map((item) => {
          if ((this.state.config?.headings[item]?.headingLabel || this.state.config?.headings[item]?.chipAlternateText) == name) {
            index = item;
            return;
          }
        });
      });
      var filteredList = null;
      if (name == 'Performer') {
        filteredList = this.filterPerformerColumn(name, value);
        finalResult = filteredList.slice();
      } else {
        filteredList = this.state.allBillingData.filter(item => filterKeyValuePair.every(f => {
          const [key] = Object.keys(f)
          return this.handleFilterfunc(item, this.getSortNode(key), f[key])
        }))
        finalResult = filteredList.slice();
      }
      this.setState({
        allFilteredData: finalResult, page: 0, rows: this.state?.rows,
        renderList: finalResult,
        filterKeyValuePair: filterKeyValuePair, filterValues
      }, () => {
        if (this.state.sortBy !== "") {
          this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
        }
      })
    }
    else {
      this.setState({
        allFilteredData: [], page: 0, rows: this.state?.rows,
        renderList: this.state.allBillingData,
        filterKeyValuePair: [], filterValues
      }, () => {
        if (this.state.sortBy !== "") {
          this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
        }
      })
    }
  }

  filterPerformerColumn(key, value) {
    let convertedToAscii = null;
    let filteredList = [];
    this.state.allBillingData?.map((item) => {
      const pkaName = (item["pka_name"] ?? '').toLowerCase();
      const creditAs = (item["credit_as"] ?? '').toLowerCase();
      const performerName = (item["performer_name"] ?? '').toLowerCase();
      const performerData = `${pkaName} ${creditAs} ${performerName}`;
      if (performerData?.includes(value)) {
        filteredList.push(item);
      }
    });
    if (filteredList.length > 0) {
      convertedToAscii = false
      return this.filterPerformerLists(convertedToAscii, value)
    } else {
      convertedToAscii = true
      return this.filterPerformerLists(convertedToAscii, value);
    }
  } 

  filterPerformerLists(convertedToAscii, value) {
    let billingData = [...this.state.allBillingData];
    return billingData?.filter((item) => {
      const normalizedValue = this.convertToAscii(value);
      const pkaName = (item["pka_name"] ?? '').toLowerCase();
      const creditAs = (item["credit_as"] ?? '').toLowerCase();
      const performerName = (item["performer_name"] ?? '').toLowerCase();
      const performerData = `${pkaName} ${creditAs} ${performerName}`;
      const normalizedLastNameValue = this.convertToAscii(performerData);
      if (convertedToAscii) {
        return (normalizedLastNameValue).includes(normalizedValue);
      } else {
        return (performerData?.includes(value));
      }
    });
  }  

  handleFilterfunc = (item, nodeName, value) => {
    if (nodeName === 'nc' || nodeName === 'et_credits' || nodeName === 'mt_credits' || nodeName === 'cut' || nodeName === 'pa_credits') {
      return item[nodeName] == 1 && value?.toLowerCase() == 'yes' ? true : (item[nodeName] == 0 || item[nodeName] === null) && value?.toLowerCase() == 'no' ? true : false
    }
    else if (nodeName === 'url') {
      return item[nodeName] && value?.toLowerCase() == 'yes' ? true : !item[nodeName] && value?.toLowerCase() == 'no' ? true : false
    }
    else if (nodeName === 'currentFileName') {
      return item[nodeName] && value?.toLowerCase() == 'yes' ? true : !item[nodeName] && value?.toLowerCase() == 'no' ? true : false
    }
    else {
      return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
    }
  }

  handleBillingFileSelect = (file, dataItem, isRepl = 0, primaryKey) => {
    this.setState({ setAttPopover: null })
    if (primaryKey === 'performer_deal_billing_id') {
      this.setState({ file: file, dataItem: dataItem, isRepl: isRepl }, () => {
        this.setState({ openNotification: 'File Selected', severity: 'success' })
        return
      });
    } else {

      let uploadFileParams = {
        "objectType": "PerformerDeal",
        "fileCategory": "billing",
        "filename": file?.name,
        // "filename": `PerformerDeal-${dataItem?.performer_deal_id}-Billing-${dataItem?.performer_deal_billing_id}.${getFileName[1]}`,
        "performerDealId": dataItem?.performer_deal_id,
        "performerDealBillingId": dataItem?.performer_deal_billing_id,
        "projectId": dataItem?.project_id
      }
      if (isRepl === 1) {
        uploadFileParams.isRemoveFile = 1
        uploadFileParams.oldFilename = dataItem?.currentFileName
        this.setState({ setAttPopover: null, billingRowData: null })
      }
      this.setState({ isSubmit: dataItem?.performer_deal_billing_id })
      return new Promise((resolve, reject) => {
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureUpload', uploadFileParams, null)
          .then(response => {
            if (response?.data?.error) {
              this.setState({ openNotification: response?.data?.message || 'File upload failed', severity: 'error', isSubmit: null })
              return
            }
            CasterService.uploadFile(response.data, file).then(res => {
              if (res?.data?.error) {
                this.setState({ openNotification: res?.data?.message || 'File upload failed', severity: 'error', isSubmit: null })
                return
              }
              resolve(this.setState({ file: null, datItem: null }));
              // return;
            }, (err) => {
              this.setState({ openNotification: 'File upload failed', severity: 'error', isSubmit: null })
            });
          }, (err) => {
            this.setState({ openNotification: 'File upload failed', severity: 'error', isSubmit: null })
          });
      });
    }
  }

  handleTableChangePage = (event, newPage) => {
    let allData = this.state.allFilteredData.length > 0 ? [...this.state.allFilteredData] : [...this.state.allBillingData]
    this.setState({
      page: newPage,
      renderList: handlePaginationList(newPage, this.state.rows, allData)
    });
  }

  handleChangeRowsPerPage = (event) => {
    let allData = this.state.allFilteredData.length > 0 ? [...this.state.allFilteredData] : [...this.state.allBillingData]
    this.setState({ rows: event.target.value, page: 0, renderList: handlePaginationList(0, event.target.value, allData) })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Performer": return "last_name";
      case "Role #": return "role_no";
      case "Role Name": return "role";
      case "Union": return "union";
      case "Deal Date": return "deal_date";
      case "MT": return "mt_credits";
      case "ET": return "et_credits";
      case "PA": return "pa_credits";
      case "Cut": return "cut";
      case "NC": return "nc";
      case "Confirmed By": return "confirmed_by";
      case "Attachment": return "currentFileName";
      case "Notes": return "notes";
    }
  }

  handleTableColumnClick = (column, origin = null) => {
    if (column === this.state.sortBy) {
      if (origin === "preserveSorting") {
        this.setState({ sortCount: this.state.sortCount }, () => {
          this.handleTableSort(column);
        })
      } else {
        this.setState({ sortCount: this.state.sortCount + 1 }, () => {
          this.handleTableSort(column);
        })
      }
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    let coltype = colName === 'Deal Date' ? 'Date' : ['MT', 'ET', 'Cut', 'NC', 'PA', 'Role #']?.includes(colName) ? 'Number' : 'Name';
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      page: 0
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: handlePaginationList(this.state.page, this.state?.rows, tableSortList(coltype, this.getSortNode(colName), (this.state.filterKeyValuePair.length > 0 ? this.state.allFilteredData : this.state.allBillingData), this.state.isSortByAsc))
      });
    })
  }

  selectFileToDelete() {
    let billingData = { ...this.state.billingRowData }
    let deleteObj = {
      "objectType": "PerformerDeal",
      "fileCategory": "billing",
      "filename": billingData?.currentFileName,
      "performerDealId": billingData?.performer_deal_id,
      "performerDealBillingId": billingData?.performer_deal_billing_id,
      "projectId": billingData?.project_id
    }
    this.setState({ deletedSelectedFile: deleteObj, setAttPopover: null, openNotification: 'Please save to delete the file', severity: 'warning' })
    this.setState({})
  }

  deleteFile = () => {
    this.setState({ setAttPopover: null, billingRowData: null, isSubmit: this.state?.deletedSelectedFile?.performer_deal_billing_id })
    return new Promise((resolve, reject) => {
      CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', this.state.deletedSelectedFile, null)
        .then(response => {
          if (response?.data?.error) {
            this.setState({ openNotification: response?.data?.message || 'File deletion failed', severity: 'error', isSubmit: null })
            return
          }
          resolve(this.setState({ deletedSelectedFile: null }));
        }, (err) => {
          this.setState({ openNotification: 'File deletion failed', severity: 'error', isSubmit: null })
        })
    })
  }

  handleOpenBillingAttachment = (e, node, value) => {
    this.setState({
      setAttPopover: e.currentTarget, billingRowData: value
    })
  };

  handleReadCallback = () => {
    let renderList = [...this.state.renderList]
    this.setState({
      renderList: renderList?.map(item => {
        let newItem = { ...item }
        newItem.editing = false
        return newItem
      })
    })
  }

  getFiles = (rowParams) => {
    let params = {
      project_id: rowParams?.project_id,
      currentFileName: rowParams?.currentFileName,
      performerDealId: rowParams?.performer_deal_id,
      performerDealBillingId: rowParams?.performer_deal_billing_id
    }
    CasterService.getDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureGetFiles`, params).then((response) => {
      if (response?.data?.error) {
        console.log("Error in getting files", response?.data?.error)
      }
      else {
        downloadUrl(response?.data, rowParams?.currentFileName);
      }
    },
      (err) => {
        console.log("Error in getting files");
      })
  }

  downloadSelectedFile = (e, node, value) => {
    this.setState({
      setAttPopover: e.currentTarget, billingRowData: value
    }, () => {
      this.getFiles(value);
      this.setState({ setAttPopover: null, billingRowData: null });
    });
  }

  render() {
    return (
      <div className='casterFeatureBillingSection'>
        <NotificationComponent
          open={this.state.openNotification ? true : false}
          message={this.state.openNotification || ""}
          severity={this.state.severity || ""}
          handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
        <Popover
          open={this.state.setAttPopover ? true : false}
          anchorEl={this.state?.setAttPopover}
          onClose={() => { this.setState({ setAttPopover: null, billingRowData: null }) }}
          anchorReference="anchorOrigin"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          anchorPosition={{ top: 100, left: 100 }}
          PaperProps={{
            style: {
              padding: '15px'
            }
          }}
        >
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical contained button group"
            variant="contained"
            classes="fileOptionButtons"
            className="fileOptionButtons"
          >
            <BasicButton text={'Replace'} variant="contained" type="inline" inputType="file" className="mt-2"
              id={'replaceFile' + this.state.billingRowData?.performer_deal_billing_id}
              uploadFile={(file) => this.handleBillingFileSelect(file, this.state.billingRowData, 1, this.state.config.primaryKey)} />
            <BasicButton
              text="Delete"
              className="mt-2"
              variant="outlined"
              type="inline"
              onClick={() => {
                this.selectFileToDelete();
              }} />
            <BasicButton
              className="mt-2"
              text="Download"
              variant="contained"
              type="inline"
              onClick={() => {
                this.getFiles(this.state.billingRowData)
                this.setState({ setAttPopover: null, billingRowData: null })
              }} />
          </ButtonGroup>
        </Popover>
        <TableComponent
          id={'feactureCastingBillingSection'}
          className={"casterFeatureBillingSection"}
          list={this.state.renderList}
          config={this.state.config}
          isLoading={this.state.isFetchingBillingData}
          readCallback={this.handleReadCallback.bind(this)}
          handleOpenBillingAttachment={this.handleOpenBillingAttachment.bind(this)}
          postInitiated={this.state.isSubmit}
          sortCallback={this.handleTableColumnClick}
          editCallback={this.handleInlineEdits.bind(this)}
          saveCallback={(data, index, pk, dataItem) => this.saveInlineEdits(data, index, pk, dataItem, 0)}
          applyFilter={this.applyFilterBillingList.bind(this)}
          handleFileSelect={this.handleBillingFileSelect.bind(this)}
          hyperLinkNavigationCallback={() => { return null; }}
          downloadFileSelect={this.downloadSelectedFile.bind(this)}
          stickyHeader={true}
        />
        <TablePagination
          component="div"
          count={this.state.allFilteredData.length > 0 ? this.state.allFilteredData.length : this.state.allBillingData ? this.state.allBillingData?.length : 0}
          rowsPerPageOptions={[25, 50, 75, 100]}
          page={this.state.page}
          onChangePage={this.handleTableChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPage={this.state.rows}
        />
      </div>
    )
  }
}

export default BillingSection;