import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import { CircularProgress } from '@material-ui/core'
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField'
import SelectField from '../SharedComponents/SelectField/SelectField'
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea'
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel'
import DateField from '../SharedComponents/DateField/DateField'
import { ssnOptions, initialAliasConfig } from './Config'
import SelectAndInputCombine from '../../Common/SelectAndInputCombine/SelectAndInputCombine'
import Radio from "@material-ui/core/Radio";
import AddGuardian from "../../Common/AddGuardian/AddGuardian"
import AddressComponent from '../../Common/AddressComponent/AddressComponent'
import InputSelectRadio from '../../Common/InputSelectRadioInline/InputSelectRadio'
import { validateEmail, validateAllPhone, getFormattedDate, formatEIDNum, checkFederalIDNum } from '../../Common/Helper'
import PhoneComponent from '../../Common/DefaultPhoneComponent/PhoneComponent'
import AliasComponent from '../../Common/AliasComponent/AliasComponent'
import SizeLimits from "../../Common/SizeLimits.json"
import Messages from '../../Common/Messages.json'
import { validateNumbersOnly, ssnFormat } from '../../../caster-animation/Common/Helper'
import ActionButtons from '../../components/SharedComponents/CommonActionButtons/BasicActionButtons'
import SearchSelectField from '../../components/SharedComponents/SearchSelectField'
export default class TalentPersonal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderOptions: [],
      ethnicityOptions: [],
      countryOptions: [],
      citizenshipOptions: [],
      socialOptions: [],
      emailOptions: [],
      phoneOptions: [],
      stateOptions: [],
      stateOptionsWithoutCode: [],
      addressOptions: [],
      immigrationStatusOptions: [],
      ssnOptions: [...ssnOptions],
      showDod: false,
      setPopOver: null,
      showImmigration: false,
      showGuardianPopup: false,
      guardianSelected: {},
      showAddressPopup: false,
      addressSelected: {},
      showUnmasked: false

    }
  }
  componentDidMount() {
    this.getLookupValues('Gender');
    this.getLookupValues('Address');
    this.getLookupValues('State',"withCode");
    this.getLookupValues('State',"withoutCode");
    this.getLookupValues('Ethnicity');
    this.getLookupValues('Country');
    this.getLookupValues('Phone');
    this.getLookupValues('Email');
    this.getLookupValues('Social');
    this.getLookupValues('IMMIGRATION_STATUS')

  }
  componentDidUpdate(prevProps, prevState) {
    console.log("DidupdateTalent")

  }
  showPopup(type, id, data = {}) {

    let htmlElement = document.getElementById(id);
    this.setState({ setPopOver: htmlElement, [`${type}Selected`]: { ...data }, })
    switch (type) {
      case "guardian":
        this.setState({ showGuardianPopup: true })
        break;
      case "address":
        this.setState({ showAddressPopup: true })
        break;

      default:
        break;
    }

  }
  onClosePopup(type) {
    this.setState({ setPopOver: null, [`${type}Selected`]: {} })
    switch (type) {
      case "guardian":
        this.setState({ showGuardianPopup: false })
        break;
      case "address":
        this.setState({ showAddressPopup: false })
        break;

      default:
        break;
    }
  }
  getLookupValues(type,format=null) {
    // casterFeatureLookup
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          let formattedList = []
          if (type == 'Gender') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ genderOptions: formattedList })
          } else if (type == 'Ethnicity') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
            this.setState({ ethnicityOptions: formattedList })
          } else if (type == 'Country') {
            let citizenshipOptions = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
            this.setState({ countryOptions: formattedList, citizenshipOptions: citizenshipOptions })
          }
          else if (type == 'Email') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ emailOptions: formattedList })
          }
          else if (type == 'State') {
            if(format==="withCode"){
              formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value, text: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value }));
              this.setState({ stateOptions: formattedList })
            }
            if(format==="withoutCode"){
              formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[1] : item.lookup_value, text: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[1] : item.lookup_value }))
              this.setState({ stateOptionsWithoutCode: formattedList })
            }
          }
          else if (type == 'Phone') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ phoneOptions: formattedList })
          } else if (type == 'Social') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ socialOptions: formattedList })
          }
          else if (type == 'IMMIGRATION_STATUS') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
            this.setState({ immigrationStatusOptions: formattedList })
          } else if (type == 'Address') {
            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ addressOptions: formattedList })
          }
        }
      }, err => {
        console.log("error from lookup")
      })

  }
  toggleCollapse(id) {
    this.props?.guardianChange(id, 'showCollapsible', true)
  }
  onAddressSave(address) {
    console.log(address);
    this.onClosePopup('address')
    this.props?.onAddressChange(this.state.addressSelected?.talent_address_id, 'address_list', address)

  }
  onGuardianSave(guardian) {
    console.log(guardian);
    this.onClosePopup('guardian')
    this.props?.guardianChange(this.state.guardianSelected?.talent_guardians_id, 'guardians_list', guardian)
  }
  getFullAddress(item) {
    let { address, city, state, country, zip } = item;
    if (state?.includes('-')) {
      let splitArray = state?.split("-")
      state = splitArray[0]
    }
    return <div>
      {address ? <p> {address} </p> : ''}
      {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
      {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
    </div>
    // return `${address ? address + '\n' : ''}  ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${zip ? zip + '\n' : ''} ${zip ? country ? country : '' :  country ?  '\n' + country  : ''}`;
  }

  getFullGuardianAddress(item) {
    let { guardian_address, guardian_city, guardian_state, guardian_country, guardian_zip } = item;
    if (guardian_state?.includes('-')) {
      let splitArray = guardian_state?.split("-")
      guardian_state = splitArray[1]
    }
    return <div className="guardianAddressBorder">
      {guardian_address ? <p> {guardian_address} </p> : ''}
      {<p>{`${guardian_city ? guardian_city : ''} ${guardian_state ? guardian_city ? ',' + guardian_state : guardian_state : ''} ${guardian_zip ? guardian_state ? ' ' + guardian_zip : guardian_city ? ', ' + guardian_zip : guardian_zip : ''}`}</p>}
      {guardian_country ? (guardian_country !== "United States" && guardian_country !== "USA" && guardian_country !== "US") ? <p> {guardian_country} </p> : '' : ''}
    </div>
    // return `${guardian_address ? guardian_address + ',' : ''} ${guardian_city ? guardian_city + ',' : ''} ${guardian_state ? guardian_state + ',' : ''} ${guardian_country ? guardian_country + ',' : ''} ${guardian_zip ? guardian_zip : ''}`;
  }
  getPhoneText(item) {
    return `${item?.value ? item?.value : ''} ${item?.ext ? 'ext.' + item?.ext : ''}`
  }
  toggleMasked() {
    this.setState((prevState) => ({
      ...prevState,
      showUnmasked: !prevState?.showUnmasked
    }))
  }
  formatSSN() {
    let ssn = this.props?.talentDetails?.ssn;
    if (ssn && !ssnFormat(ssn) && ssn?.length == SizeLimits?.ssnCharacterLimit) {
      let result = ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, ssn.length);
      if (ssnFormat(result)) {
        this.props?.handleGeneralEdit('ssn', result)
      }
    }
    return ssn;
  }
  render() {
    let ethnicityValues = this.props?.talentDetails?.ethnicity_list?.length > 0 ? this.props?.talentDetails?.ethnicity_list?.map(item => item.text) : []
    return (
      <MDBContainer className='TalentPersonalContainer'>
        {this.props?.isLoading ?
          <CircularProgress />
          :
          <>
            {this.props?.isEditing ?
              <MDBRow>
                <MDBCol>
                  <BasicTextField
                    id="firstName"
                    label="First Name"
                    value={this.props?.talentDetails?.first_name}
                    onChange={(e) => this.props?.handleGeneralEdit('first_name', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                    isAlphabetVar={true}
                    handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('first_name', value)}
                  />
                </MDBCol>
                <MDBCol>
                  <BasicTextField
                    id="middleName"
                    label="Middle Name"
                    value={this.props?.talentDetails?.middle_name}
                    onChange={(e) => this.props?.handleGeneralEdit('middle_name', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                    isAlphabetVar={true}
                    handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('middle_name', value)}
                  />
                </MDBCol>
                <MDBCol>
                  <BasicTextField
                    id="lastName"
                    label="Last Name"
                    value={this.props?.talentDetails?.last_name}
                    onChange={(e) => this.props?.handleGeneralEdit('last_name', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                    isAlphabetVar={true}
                    handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('last_name', value)}
                  />
                </MDBCol>
                <MDBCol>
                  <BasicTextField
                    id="suffixTalent"
                    label="Suffix"
                    value={this.props?.talentDetails?.suffix}
                    onChange={(e) => this.props?.handleGeneralEdit('suffix', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                    isAlphabetVar={true}
                    handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('suffix', value)}
                  />
                </MDBCol>
              </MDBRow> :
              <MDBRow>
                <BasicLabel text={"Name"} />
                <div className={`displayName`}>{`${this.props?.talentDetails?.first_name ? this.props?.talentDetails?.first_name : ''} ${this.props?.talentDetails?.middle_name ? this.props?.talentDetails?.middle_name + ' ' : ' '} ${this.props?.talentDetails?.last_name ? this.props?.talentDetails?.last_name : ''} ${this.props?.talentDetails?.suffix ? this.props?.talentDetails?.suffix : ''}` || '-'}</div>
              </MDBRow>
            }
            {this.props?.isEditing &&

              <AliasComponent
                aliasDetails={this.props?.talentDetails?.akas}
                onAliasSave={this.props?.onAliasSave?.bind(this)}
                module_type={"Talent"}
                initialAliasConfig={initialAliasConfig}
              />

            }
            <MDBRow>
              <MDBCol md={12}>
                <div className="sectionHeaderText">Personal</div>
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="gender"
                  label="Gender"
                  options={this.state?.genderOptions}
                  isMandatory={true}
                  showMandatory={this.props?.showError}
                  value={this.props?.talentDetails?.gender}
                  onChange={(e) => this.props?.handleGeneralEdit('gender', e.target?.value)}
                  showAsLabel={!this.props?.isEditing}
                  stateLabel={!this.props?.isEditing}
                />
              </MDBCol>
              <MDBCol md={3} className={`${this.state?.talentDetails?.ethnicity_list?.length === 0 ? "searchSelectHeight searchSelect" : "searchSelect"}`}
                id='formatDropDown'>
                {/* <SelectField
                  id="ethnicity"
                  label="Ethnicity"
                  isMandatory={true}
                  showMandatory={this.props?.showError}
                  options={this.state?.ethnicityOptions}
                  value={this.props?.talentDetails?.ethnicity_name}
                  onChange={(e) => this.props?.handleGeneralEdit('ethnicity_name', e.target?.value)}
                  showAsLabel={!this.props?.isEditing}
                  stateLabel={!this.props?.isEditing}
                /> */}
                <SearchSelectField
                  id={"ethnicity"}
                  label={"Ethnicity"}
                  isMandatory={true}
                  showMandatory={this.props?.showError}
                  value={this.props?.talentDetails?.ethnicity_list || []}
                  options={this.state?.ethnicityOptions || []}
                  showAsLabel={!this.props?.isEditing}
                  stateLabel={!this.props?.isEditing}
                  // limitTags={4}
                  onChange={(e, value) => this.props?.handleGeneralEdit('ethnicity_list', value)}
                  multiple={true}
                  isTalent={true}
                  displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="citizenship"
                  label="Citizenship"
                  options={this.state?.citizenshipOptions}
                  value={this.props?.talentDetails?.citizen_of}
                  onChange={(e) => this.props?.handleGeneralEdit('citizen_of', e.target?.value)}
                  showAsLabel={!this.props?.isEditing}
                  stateLabel={!this.props?.isEditing}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  id="SAGId"
                  label="SAG ID"
                  value={this.props?.talentDetails?.sag_id}
                  onChange={(e) => this.props?.handleGeneralEdit('sag_id', e.target?.value)}
                  showAsLabel={!this.props?.isEditing}
                // showDuplicate={this.props?.sagError}
                // showDuplicateMesg={this.props?.sagErrorMessage}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3}>
                {this.props?.isEditing ?

                  <SelectAndInputCombine
                    id="ssn"
                    label="SSN/FID"
                    options={this.state.ssnOptions}
                    value={this.props?.talentDetails?.ssn_type}
                    onChange={(e) => this.props?.handleGeneralEdit('ssn_type', e.target?.value)}
                    inputValue={this.props?.talentDetails?.ssn_type == 'ssn' ? this.props?.talentDetails?.ssn : this.props?.talentDetails?.ssn_type == 'fid' ? this.props?.talentDetails?.f_id : '' || ''}
                    onInputChange={(e) => this.props?.handleGeneralEdit(this.props?.talentDetails?.ssn_type == 'fid' ? 'f_id' : 'ssn', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                    inputLimit={this.props?.talentDetails?.ssn_type == 'ssn' ? this.props?.talentDetails?.ssn && ssnFormat(this.props?.talentDetails?.ssn) ? SizeLimits.formattedSSN : SizeLimits.ssnCharacterLimit : undefined}
                    inputWarning={Messages?.ssnWarning}
                    infoMsg='SSN number is of 9 digit & format should be eg.xxx-xx-xxxx'
                    onBlur={this.props?.talentDetails?.ssn_type == 'ssn' ? this.props?.talentDetails?.ssn ? () => this.formatSSN() : undefined : undefined}
                  // showDuplicateSSN={this.props?.ssnError}
                  />
                  :
                  <>
                    <BasicLabel text="SSN/FID" />
                    {(this.props?.talentDetails?.ssn || this.props?.talentDetails?.f_id) &&
                      <div className='labelText'>
                        {this.props?.talentDetails?.ssn_type == 'ssn' ? `SSN-${!this.state.showUnmasked ? this.props?.talentDetails?.masked_ssn : this.props?.talentDetails?.ssn}` : this.props?.talentDetails?.ssn_type == 'fid' ? `Foreign Id-${this.props?.talentDetails?.f_id}` : ''}
                        {this.props?.talentDetails?.ssn_type == 'ssn' && this.props?.talentDetails?.ssn && (
                          <MDBIcon className='eyeIconMasked' icon={this.state.showUnmasked ? 'eye' : 'eye-slash'} onClick={() => this.toggleMasked()} />
                        )}
                      </div>
                    }
                  </>
                }
              </MDBCol>
              <MDBCol md={2}>
                {this.props?.isEditing && <DateField
                  id="dob"
                  label="DOB"
                  value={this.props?.talentDetails?.birth_date}
                  onChange={(e) => this.props?.handleGeneralEdit('birth_date', e.target?.value)}
                />
                }

                {!this.props?.isEditing ? <> <BasicLabel text="DOB" /><div className={this.props?.talentDetails?.age < 18 ? 'labelText minorAge' : 'labelText'}>{this.props?.talentDetails?.birth_date ? getFormattedDate(this.props?.talentDetails?.birth_date) + ' ' + '(' + this.props?.talentDetails?.age + `yrs.old)` : '-'}</div> </> : ''}
              </MDBCol>
              <MDBCol md={1}>
                {this.props?.isEditing && <>
                  <BasicLabel text="Age" />
                  <div className={this.props?.talentDetails?.age < 18 ? 'labelText minorAge' : 'labelText'}>{this.props?.talentDetails?.birth_date ? this.props?.talentDetails?.age + `yrs` : '-'}</div>
                </>}
              </MDBCol>
              <MDBCol md={2} className="DODWrapper">
                {!this.state?.showDod && !this.props?.talentDetails?.death_date && this.props?.isEditing &&
                  <div className='addLabel' onClick={() => this.setState({ showDod: true })}>
                    <span>
                      <MDBIcon icon="plus-circle" />
                      Add DOD
                    </span>
                  </div>
                }
                {(this.state?.showDod || this.props?.talentDetails?.death_date) && this.props?.isEditing &&
                  <DateField
                    id="dod"
                    label="DOD"
                    value={this.props?.talentDetails?.death_date}
                    onChange={(e) => this.props?.handleGeneralEdit('death_date', e.target?.value)}
                    showAsLabel={!this.props?.isEditing}
                  />
                }
                {((this.state?.showDod || this.props?.talentDetails?.death_date) && !this.props?.isEditing) && <> <BasicLabel text="DOD" /><div className={this.props?.talentDetails?.death_date ? 'redText' : 'labelText'}>{this.props?.talentDetails?.death_date ? getFormattedDate(this.props?.talentDetails?.death_date): ''}</div></>}
              </MDBCol>
              <MDBCol md={4}>
                {!this.state?.showImmigration && !this.props?.talentDetails?.immigration_status && this.props?.isEditing &&
                  <div className='addLabel' onClick={() => this.setState({ showImmigration: true })}>
                    <span>
                      <MDBIcon icon="plus-circle" />
                      Add Immigration
                    </span>
                  </div>
                }
                {this.props?.talentDetails?.immigration_status && !this.props?.isEditing && <>
                  <BasicLabel text="Immigration Status" />
                  <div className='labelText'>{`${this.props?.talentDetails?.immigration_status ? this.props?.talentDetails?.immigration_status?.text : ''}${this.props?.talentDetails?.expiry_date ? ' exp. ' + getFormattedDate(this.props?.talentDetails?.expiry_date) : ''}`}</div>
                </>
                }
                {(this.state?.showImmigration || (this.props?.talentDetails?.immigration_status && this.props?.isEditing)) &&
                  <div className='ImmigrationFieldContainer'>
                    <MDBRow>
                      <MDBCol md={7} className='col'>
                        <SearchSelectField
                          id={"immigration"}
                          label={"Immigration Status"}
                          isMandatory={true}
                          showMandatory={this.props?.showError}
                          value={this.props?.talentDetails?.immigration_status || []}
                          options={this.state.immigrationStatusOptions || []}
                          showAsLabel={!this.props?.isEditing}
                          stateLabel={!this.props?.isEditing}
                          // limitTags={4}
                          onChange={(e, value) => this.props?.handleGeneralEdit('immigration_status', value)}
                        // multiple={true}
                        // isTalent={true}
                        // displayValues={this.props?.talentDetails?.immigration_status ? this.props?.talentDetails?.immigration_status?.text : "--"}
                        />
                        {/* <SelectField
                          id="immigration"
                          label="Immigration Status"
                          options={this.state.immigrationStatusOptions}
                          value={this.props?.talentDetails?.immigration_status}
                          onChange={(e) => this.props?.handleGeneralEdit('immigration_status', e.target?.value)}
                          isMandatory={true}
                          showMandatory={this.props?.showError}
                        /> */}
                      </MDBCol>
                      <MDBCol md={5} className="col">
                        <DateField
                          id="expiryDate"
                          label={'  '}
                          value={this.props?.talentDetails?.expiry_date}
                          onChange={(e) => this.props?.handleGeneralEdit('expiry_date', e.target?.value)}
                        />
                      </MDBCol>
                    </MDBRow>
                  </div>
                }
              </MDBCol>
            </MDBRow>

            {((this.props?.talentDetails?.birth_date && this.props?.talentDetails?.age < 18) || (this.props?.talentDetails?.birth_date && this.props?.talentDetails?.age > 18 && this.props?.talentDetails?.guardians_list?.length>0)) && (<>
              <MDBRow className='guradianRowHeader'>
              {this.props?.isEditing ?                
                  <div className='addLabel' id="newGuardianBtn" onClick={() => this.showPopup('guardian', 'newGuardianBtn', 'new')}>
                    <span>
                      <MDBIcon icon="plus-circle" />
                      PARENT/GUARDIAN
                    </span>
                  </div> 
                 :
                <div className='headerText'>PARENT/GUARDIAN</div>
              }
                {this.props?.showError && this.props?.talentDetails?.age < 18 && this.props?.talentDetails?.birth_date && this.props?.talentDetails?.guardians_list?.length == 0 ?
                  <div className='errorText'>Incase of minor guardian is required!</div>
                  : null}
              </MDBRow>
              {this.state?.showGuardianPopup &&
                <AddGuardian
                  open={this.state?.showGuardianPopup}
                  isEditing={this?.props?.isEditing}
                  setPopOver={this.state?.setPopOver}
                  handleClose={this.onClosePopup.bind(this, 'guardian')}
                  onSave={(guardian) => { this.onGuardianSave(guardian) }}
                  mode={this.state.guardianSelected?.talent_guardians_id ? 'edit' : 'new'}
                  id={this.state.guardianSelected?.talent_guardians_id}
                  selectedGuardian={this.state?.guardianSelected}
                  stateOptions={this.state?.stateOptions}
                  countryOptions={this.state?.countryOptions}
                  emailOptions={this.state?.emailOptions}
                  phoneOptions={this.state?.phoneOptions}
                  addressOptions={this.state?.addressOptions}
                />}
            </>)
            }
              {this.props?.talentDetails?.guardians_list?.filter(i => !i.is_delete)?.map((item, index) => (
                <>
                  <MDBRow className='guradianRow' key={`header_${item?.talent_guardians_id}`}>
                    <MDBCol md={11} className='noPadding' onClick={() => this.toggleCollapse(item?.talent_guardians_id)}>
                      <MDBRow>
                        <MDBCol md={3}>
                          <div className={`small-text guardianName ${item?.is_primary == 1 ? 'primaryStarIcon' : ''}`}>
                            <div className='upperCase'>{item?.relation}</div>
                            <div className={`spanText leftBorder`}>{item?.guardian_name}</div>
                          </div>
                        </MDBCol>
                        <MDBCol md={3}>
                          {item?.guardian_phone_list?.filter((i) => !i?.is_delete)?.map((phoneItem, index) => {
                            if (index == 0) {
                              return (
                                <div className='small-text'>
                                  <div className=''>{phoneItem?.object_type}</div>
                                  <div className={`spanText leftBorder ${phoneItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{this.getPhoneText(phoneItem) || ''}</div>
                                </div>)

                            }
                            return null;
                          }

                          )}

                        </MDBCol>
                        <MDBCol md={3}>
                          {item?.guardian_email_list?.filter(i => !i?.is_delete)?.map((emailItem, index) => {
                            if (index == 0) {
                              return (
                                <div className='small-text'>
                                  <div className=''>{emailItem?.object_type}</div>
                                  <div className={`spanText leftBorder ${emailItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{emailItem?.value}</div>
                                </div>)
                            }
                            return null;
                          }
                          )}

                        </MDBCol>

                        <MDBCol md={3}>
                          <div className='small-text'>
                            <div className='spanText'>{item?.guardian_address_type}</div>
                            <div className='spanText leftBorder'>{this.getFullGuardianAddress(item)}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={1} className='noPadding iconContainer'>

                      {!item?.showCollapsible ? <MDBIcon icon="chevron-down" onClick={() => this.toggleCollapse(item?.talent_guardians_id)} /> :
                        <MDBIcon icon="chevron-up" onClick={() => this.toggleCollapse(item?.talent_guardians_id)} />}
                      {this.props?.isEditing && (
                        <>
                          <MDBIcon icon="arrows-alt-v" className='transform_arrow' onClick={() => this.showPopup('guardian', 'newGuardianBtn', item)} />
                          <input
                            name='guradianPrimary'
                            id={`radio_${item?.talent_guardians_id}`}
                            size="small"
                            type="radio"
                            value={item?.talent_guardians_id}
                            defaultChecked={item?.is_primary == 1 ? true : false}
                            onChange={(e) => this.props?.guardianChange(item?.talent_guardians_id, 'is_primary', 1)}
                          />
                          <MDBIcon icon="times" onClick={() => this.props?.guardianChange(item?.talent_guardians_id, 'is_delete', 1)} />
                        </>
                      )}

                    </MDBCol>
                  </MDBRow>

                  <MDBRow key={`collapse_${item?.talent_guardians_id}`} className={`guradianRow collapse ${item?.showCollapsible ? 'show' : ''}`}>
                    <MDBCol md={10} className='noPadding'>
                      <MDBRow>
                        <MDBCol md={3}>
                          <span>  </span>

                        </MDBCol>
                        <MDBCol md={3}>
                          {item?.guardian_phone_list?.filter((i) => !i?.is_delete)?.map((phoneItem, index) => {
                            if (index != 0) {
                              return (
                                <div className='small-text'>
                                  <span className=''>{phoneItem?.object_type}</span>
                                  <span className={`spanText leftBorder ${phoneItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{this.getPhoneText(phoneItem) || ''}</span>
                                </div>)

                            }
                            return null;
                          }

                          )}
                        </MDBCol>
                        <MDBCol md={3}>
                          {item?.guardian_email_list?.filter(i => !i?.is_delete)?.map((emailItem, index) => {
                            if (index != 0) {
                              return (
                                <div className='small-text'>
                                  <span className=''>{emailItem?.object_type}</span>
                                  <span className={`spanText leftBorder ${emailItem?.is_primary == 1 ? 'primaryStarIconBorder' : ''}`}>{emailItem?.value}</span>
                                </div>)

                            }
                            return null;
                          }
                          )}
                        </MDBCol>

                        <MDBCol md={3}>
                          <span>  </span>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                  </MDBRow>

                </>
              ))}



            <MDBRow>
              <MDBCol md={5}>
                <BasicTextArea
                  id="notes"
                  label="Notes"
                  rows={2}
                  value={this.props?.talentDetails?.notes}
                  onChange={(e) => this.props?.handleGeneralEdit('notes', e.target?.value)}
                  showAsLabel={!this.props?.isEditing}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12}>
                <div className="sectionHeaderText">Contact Details</div>
              </MDBCol>
              <MDBCol md={5}>
                {this.props?.isEditing ?
                  <>
                    <BasicLabel text={"Address"} />
                    <div className='addLabel' id="newAddressBtn" onClick={() => this.showPopup('address', 'newAddressBtn', 'new')}>
                      <span>
                        <MDBIcon icon="plus-circle" />
                        Add Address
                      </span>
                    </div>
                  </>
                  :
                  <div className='headerText'>Address</div>
                }

                {this.props?.talentDetails?.address_list?.filter(i => i?.is_delete == 0 || !i?.is_delete)?.map((item, index) => (
                  <>
                    <MDBRow className='addressRow'>
                      <MDBCol md={3} className="noPadding">
                        <p className='spanText'>{item?.address_type}</p>
                      </MDBCol>
                      <MDBCol md={6}>
                        <p className={`spanText ${item?.is_primary == 1 && !this.props?.isEditing ? 'primaryStarIcon' : ''}`}>{this.getFullAddress(item)}</p>
                      </MDBCol>
                      <MDBCol md={3} className='noPadding iconContainer'>
                        {this.props?.isEditing && (
                          <>
                            <MDBIcon icon="pen"
                              // className='transform_arrow'
                              onClick={() => this.showPopup('address', 'newAddressBtn', item)} />
                            <input
                              name='addressPrimary'
                              id={`radioAddress_${item?.talent_address_id}`}
                              size="small"
                              type="radio"
                              value={item?.talent_address_id}
                              defaultChecked={item?.is_primary == 1 ? true : false}
                              onChange={(e) => this.props?.onAddressChange(item?.talent_address_id, 'is_primary', e)}
                            />
                            <MDBIcon icon="times" onClick={() => this.props?.onAddressChange(item?.talent_address_id, 'is_delete', 1)} />
                          </>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </>
                ))}
              </MDBCol>
              {this.state?.showAddressPopup &&
                <AddressComponent
                  open={this.state?.showAddressPopup}
                  setPopOver={this.state?.setPopOver}
                  handleClose={this.onClosePopup.bind(this, 'address')}
                  onSave={(address) => { this.onAddressSave(address) }}
                  mode={this.state.addressSelected?.talent_address_id ? 'edit' : 'new'}
                  id={this.state.addressSelected?.talent_address_id || null}
                  selectedAddress={this.state?.addressSelected}
                  isTalent={true}
                />
              }
              <MDBCol md={7}>
                <MDBRow>
                  <MDBCol md={6}>
                    {/* <div className='headerText'>Phone</div> */}
                    {this.props?.talentDetails?.phone_list?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                      <PhoneComponent
                        radioGroupName='talentPhoneRadio'
                        showAsLabel={!this.props?.isEditing}
                        isEditing={this.props?.isEditing}
                        objectItem={item}
                        ext={true}
                        inValidInput={"Please enter valid phone"}
                        addCallback={(obj) => this.props?.addRemoveObject('phone_list', obj)}
                        removeCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                        editCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                        selectOptions={this.state?.phoneOptions}
                        showAdd={index == 0 ? true : false}
                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                        inputValidateMethod={validateAllPhone}
                        isTalent={true}
                        showLabel={index == 0 ? true : false}
                        labelNew={"Phone"}
                        addButton={index == 0 ? true : false}
                      />
                    ))}
                  </MDBCol>
                  <MDBCol md={6}>
                    {/* <div className='headerText'>Email</div> */}
                    {this.props?.talentDetails?.email_list?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => (
                      <InputSelectRadio
                        radioGroupName='talentEmailRadio'
                        inputPlaceholder="Enter Email"
                        showAsLabel={!this.props?.isEditing}
                        isEditing={this.props?.isEditing}
                        hideActions={!this.props?.isEditing}
                        objectItem={emailItem}
                        addCallback={(emailObj) => this.props?.addRemoveObject('email_list', emailObj)}
                        removeCallback={(id, key, value) => this.props?.onChangeObject('email_list', id, key, value)}
                        editCallback={(id, key, value) => this.props?.onChangeObject('email_list', id, key, value)}
                        selectOptions={this.state?.emailOptions}
                        showAdd={index == 0 ? true : false}
                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                        inputValidateMethod={validateEmail}
                        isTalent={true}
                        showLabel={index == 0 ? true : false}
                        labelNew={"Email"}
                        addButton={index == 0 ? true : false}
                        selectFieldLength={4}
                        textFieldLength={8}
                      />
                    ))}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12}>
                <div className="sectionHeaderText">Loanout</div>
              </MDBCol>

              {this.props?.talentDetails?.legal_list?.filter(i => !i?.is_delete)?.map((item, index) => (

                <MDBCol md={12} className={`loanoutRow ${this.props?.isEditing ? 'col noColPadding' : ''}`}>
                  <MDBCol md='3' className='companyInput'>
                    <BasicTextField
                      id="loanoutCompTxt"
                      placeholder='Loanout Company (required)'
                      value={item?.loanout_company}
                      onChange={(e) => this.props?.onChangeLoanout(item?.talent_legal_id, 'loanout_company', e.target?.value)}
                      showAsLabel={!this.props?.isEditing}
                      // isPrimary={item?.is_primary ? true : false}
                      showMandatory={this.props?.showLegalMandatory}
                      isMandatory={true}
                    />
                  </MDBCol>
                  <MDBCol md='3' className='stateSelect'>
                    <SearchSelectField
                      id={"loanOutState"}
                      placeholder="State of Inc."
                      // label={"Immigration Status"}
                      // isMandatory={true}
                      // showMandatory={this.props?.showError}
                      value={item?.state_inc || []}
                      options={this.state.stateOptionsWithoutCode || []}
                      showAsLabel={!this.props?.isEditing}
                      stateLabel={!this.props?.isEditing}
                      // limitTags={4}
                      onChange={(e, value) => this.props?.onChangeLoanout(item?.talent_legal_id, 'state_inc', value)}
                      // multiple={true}
                      isTalent={true}
                      displayValues={item?.state_inc?.text}
                      disabled={item?.country_inc?.value == 'United States' || item?.country_inc?.value == 'USA' || item?.country_inc?.value == 'US' || !item?.country_inc?.value ? false : true}
                    />
                    {/* <SelectField
                      id="loanOutState"
                      placeholder="State of Inc."
                      options={this.state?.stateOptions}
                      value={item?.state_inc}
                      onChange={(e) => this.props?.onChangeLoanout(item?.talent_legal_id, 'state_inc', e.target?.value)}
                      showAsLabel={!this.props?.isEditing}
                      stateLabel={!this.props?.isEditing}
                    // disabled={item?.country_inc == 'United States' || item?.country_inc == 'USA' || item?.country_inc == 'US' || !item?.country_inc ? false : true}
                    /> */}
                  </MDBCol>
                  <MDBCol md='3' className='countrySelect'>
                    <SearchSelectField
                      id={"loanOutCountry"}
                      placeholder="Country of Inc."
                      // label={"Immigration Status"}
                      // isMandatory={true}
                      // showMandatory={this.props?.showError}
                      value={item?.country_inc || []}
                      options={this.state.countryOptions || []}
                      showAsLabel={!this.props?.isEditing}
                      stateLabel={!this.props?.isEditing}
                      // limitTags={4}
                      onChange={(e, value) => this.props?.onChangeLoanout(item?.talent_legal_id, 'country_inc', value)}
                      // multiple={true}
                      isTalent={true}
                      displayValues={item?.country_inc?.text}
                    />
                    {/* <SelectField
                      id="loanOutCountry"
                      placeholder="Country of Inc."
                      options={this.state?.countryOptions}
                      // value={this.props?.isEditing ? (item?.country_inc || 'United States') : item?.country_inc}
                      value={item?.country_inc}
                      onChange={(e) => this.props?.onChangeLoanout(item?.talent_legal_id, 'country_inc', e.target?.value)}
                      showAsLabel={!this.props?.isEditing}
                      stateLabel={!this.props?.isEditing}
                    /> */}
                  </MDBCol>
                  <MDBCol md='2' className='eidInput'>
                    <BasicTextField
                      id="EID"
                      placeholder='EID'
                      value={item?.federal_id_no}
                      onChange={(e) => (item?.country_inc?.value == 'United States' || item?.country_inc?.value == 'USA' || item?.country_inc?.value == 'US') ? this.props.onChangeLoanout(item?.talent_legal_id, 'federal_id_no', formatEIDNum(e.target.value)) : this.props?.onChangeLoanout(item?.talent_legal_id, 'federal_id_no', e.target?.value)}
                      // onChange={(e) => this.props?.onChangeLoanout(item?.talent_legal_id, 'federal_id_no', e.target?.value)}
                      showAsLabel={!this.props?.isEditing}
                      fieldValid={item?.federal_id_no ? ((item?.country_inc?.value == 'United States' || item?.country_inc?.value == 'USA' || item?.country_inc?.value == 'US') ? (!checkFederalIDNum(item?.federal_id_no) ? true : false) : false) : false}
                      inValidInput={Messages.invalidInput}
                      isPrimary={item?.is_primary ? true : false}
                    />
                  </MDBCol>
                  <MDBCol md='1'>
                    {this.props?.isEditing && (
                      <ActionButtons
                        keyName={this.props?.keyName}
                        showAdd={index == 0 ? true : false}
                        showRemove={index != 0 ? true : false}
                        showRadio={index != 0 ? true : false}
                        radioGroupName='loanOutRadioGrp'
                        isPrimary={item?.is_primary == 1 ? true : false}
                        value={1}
                        addCallback={() => this.props?.addLoanoutCompany(item)}
                        removeCallback={() => this.props?.onChangeLoanout(item?.talent_legal_id, 'is_delete', 1)}
                        primaryOnChange={(e) => this.props?.onChangeLoanout(item?.talent_legal_id, 'is_primary', 1)}
                        isEditing={this.props?.isEditing}
                      />
                    )}
                  </MDBCol>
                </MDBCol>

              ))}
            </MDBRow>
          </>
        }

      </MDBContainer>
    )
  }
}
