import React from 'react';
import { MDBIcon } from 'mdbreact';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import BasicLabel from './BasicLabel/BasicLabel';
import './shared.scss';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

export default class SearchSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatoryWarning: "Please complete this mandatory field"
        }
    }

    render() {
        let error = (this.props.showMandatory && (!this.props.searchSelect ? !this.props?.value.value : this.props?.detail_selected?.length > 0 ? false : true)) || false;
        return (
            (this.props.showAsLabel && !this.props?.isTalent ?
                (<div className="showAsLabel">{this.props?.detail_selected?.text || ""} </div>) :
                (this.props.showAsLabel && this.props?.isTalent ?
                    (<div>
                        {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                            isMandatory={this.props?.isMandatory || false} />}
                        <div className="showAsLabel">{this.props?.displayValues}</div></div>) :
                    (!this.props.searchSelect ?
                        this.props.multiple ?
                            <div className={`SearchSelectFieldContainer ${this.props?.className} `}>
                                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                                    isMandatory={this.props?.isMandatory || false} />}
                                <Autocomplete
                                    className={`${error ? "error" : ""}`}
                                    id={this.props?.id} multiple limitTags={this.props.limitTags || 1}
                                    disabled={this.props?.disabled ? this.props?.disabled : false}
                                    options={this.props?.options ? this.props?.options : []}
                                    getOptionDisabled={(option) => option.is_active === 0 ? true : false}
                                    value={this.props?.value ? this.props?.value : null}
                                    getOptionLabel={(option) => option.text ? option.text : ''}
                                    getOptionSelected={(option, value) => option.value === value}
                                    filterOptions={filterOptions}
                                    style={{ width: this.props.width ? this.props.width : '200px' }}
                                    popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                                    onChange={this.props?.onChange}
                                    autoHighlight={this.props?.id === "codeSelect" ? true : false}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={this.props?.isMandatory || false}
                                            placeholder={this.props?.placeholder}
                                        />
                                    }
                                />
                                {error && <div className="errorText">{(this.props.showMandatory && this.props?.value?.length === 0) ? this.state.mandatoryWarning : ""}</div>}
                            </div> :
                            <div className={`${this.props.className ?
                                `SearchSelectFieldContainer ${this.props.className}` :
                                `SearchSelectFieldContainer `} `}
                            >
                                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                                    isMandatory={this.props?.isMandatory || false} />}
                                <Autocomplete
                                    className={`${error ? "error" : ""}`}
                                    id={this.props?.id} limitTags={1}
                                    disabled={this.props?.disabled ? this.props?.disabled : false}
                                    options={this.props?.options ? this.props?.options : []}
                                    getOptionDisabled={(option) => option.is_active === 0 ? true : false}
                                    value={this.props?.value ? this.props?.value : null}
                                    getOptionLabel={(option) => option.text ? option.text : ''}
                                    getOptionSelected={(option, value) => option.value === value}
                                    filterOptions={filterOptions}
                                    style={{ width: this.props.width ? this.props.width : '200px' }}
                                    popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                                    onChange={this.props?.onChange}
                                    autoHighlight={this.props?.id === "codeSelect" ? true : false}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required={this.props?.isMandatory || false}
                                            placeholder={this.props?.placeholder}
                                        />
                                    }
                                />
                                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value.value) ? this.state.mandatoryWarning : ""}</div>}
                            </div> :
                        <div className={`${this.props.className ?
                            `SearchSelectFieldContainer ${this.props.className}` :
                            `SearchSelectFieldContainer`} `}
                        >
                            {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                                isMandatory={this.props?.isMandatory || false} />}
                            <Autocomplete
                                className={`${error ? "error" : ""}`}
                                id={this.props?.id}
                                disabled={this.props?.disabled ? this.props?.disabled : false}
                                options={this.props?.options ? this.props?.options : []}
                                getOptionDisabled={(option) => option.is_active === 0 ? true : false}
                                value={(this.props?.detail_selected ? this.props?.detail_selected : null)}
                                getOptionLabel={(option) => option.text ? option.text : ''}
                                getOptionSelected={(option, value) => option.value === value}
                                filterOptions={filterOptions}
                                style={{ width: this.props.width ? this.props.width : '200px' }}
                                popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                                onChange={this.props?.valueSelected}
                                autoHighlight={this.props?.id === "codeSelect" ? true : false}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required={this.props?.isMandatory || false}
                                        placeholder={this.props?.placeholder}
                                        textFieldId={this.props?.textFieldId}
                                        onChange={this.props?.searchText}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {(this.props?.detail_selected === undefined && this.props?.loading) ? <CircularProgress color="inherit" size={15} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />}
                            />
                            {error && <div className="errorText">{(this.props.showMandatory && this.props?.detail_selected?.text?.length > 0 ? false : true) ? this.state.mandatoryWarning : ""}</div>}
                        </div>

                    )
                )
            )
        );
    }
}