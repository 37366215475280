import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import { withUserContext } from "../../../contexts/UserContext";
import DateField from '../SharedComponents/DateField/DateField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import ReportButton from './ReportButton';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { downloadUrl, extractFilenameFromUrl, formatDailyRate } from "../../Common/Helper";
import SearchSelectField from '../SharedComponents/SearchSelectField';
import './TalentVoucher.scss'
import MessageModal from '../SharedComponents/MessageModal';

class TalentVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails: [],
            TalentVoucherModal: false,
            pdfRes: null
        }
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true })
        let performerId = this.props?.performerOption?.filter(item => item.value === this.props?.reportDetails?.performer_id)?.[0]?.performer_id;
        let contract_id = this.props?.performerOption?.filter(item => item.value === this.props?.reportDetails?.performer_id)?.[0]?.contract_id;
        let searchPayload = {
            show_id: this.props?.reportDetails.show_id.value,
            show_season_id: this.props?.reportDetails.show_season_id,
            episode_id: this.props?.reportDetails.episode_id,
            performer_id: performerId,
            contract_id: contract_id,
            start_date: this.props?.reportDetails.start_date,
            account_details: this.props?.reportDetails.account_details,
            contract_text: this.props?.reportDetails.contract_text,
            rate: this.props?.reportDetails?.rate
        };

        Object.keys(searchPayload).map((obj) => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        });
        let encryptTedStr = btoa(JSON.stringify(searchPayload));

        let load = {
            payload: "casterAnimationGenerateReport",
            reportName: "rptTalentVoucher",
            fileName: "rptTalentVoucher",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1
        };
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200 && response?.body?.message) {
                    this.setState({ TalentVoucherModal: true, pdfRes: response.body.message })
                } else {
                    this.setState({ postInitiated: false })
                    const filename = extractFilenameFromUrl(response?.body);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(response?.body, decodedFile);
                }
            })
        );
    }

    render() {
        const { talentVoucherShow } = this.props;
        return (
            <div>
                <MDBContainer className="reportContainer TalentVoucherContainer">
                    <span className="header" style={{ fontSize: "10px" }}>
                        The file will generate in your browser as PDF.
                    </span>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SearchSelectField
                                id={"show"}
                                label={"Select Show"}
                                defaultMenuText={"-"}
                                options={this.props?.showOption}
                                valueSelected={(e, value) =>
                                    this.props.handleGeneralEdit("show_id", value)
                                }
                                searchSelect={true}
                                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                                detail_selected={this.props?.reportDetails?.show_id}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"season"}
                                value={this.props?.reportDetails?.show_season_id}
                                label={"Select season"}
                                defaultMenuText={"-"}
                                options={this.props?.seasonOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("show_season_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"episode"}
                                value={this.props?.reportDetails?.episode_id}
                                label={"Select episode"}
                                defaultMenuText={"-"}
                                options={this.props?.episodeOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("episode_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"performer"}
                                value={this.props?.reportDetails?.performer_id}
                                label={"Select Performer"}
                                defaultMenuText={"-"}
                                options={this.props?.performerOption}
                                onChange={(e) =>{
                                     this.props.handleGeneralEdit("performer_id", e.target.value)
                                }    
                                }
                            />
                        </MDBCol>

                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}>
                            <DateField
                                id="selectWorkDate"
                                value={this.props?.reportDetails.start_date || ""}
                                label={"Start Date"}
                                onChange={(e) => this.props.handleGeneralEdit('start_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                        <MDBCol md={8}>
                            <BasicTextField
                                className="text"
                                label={"Account Details"}
                                value={this.props?.reportDetails.account_details}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("account_details", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <BasicTextField
                                className="text"
                                label={"Contract Text"}
                                value={this.props?.reportDetails?.contract_text}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("contract_text", e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md={4}>
                            <BasicTextField
                                className="rate"
                                label={"Rate"}
                                placeholder="$0.00"
                                value={this.props?.reportDetails?.rate || ""}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("rate", e.target.value)
                                }
                                onBlur={(e) => {
                                    const formattedValue = formatDailyRate(e.target.value);
                                    this.props.handleGeneralEdit("rate", formattedValue);
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                        postInitiated={this.state.postInitiated}
                    />
                </MDBContainer>
                {
                    this.state.TalentVoucherModal &&
                    <MessageModal
                        title={"PDF Download Failed"}
                        open={this.state.TalentVoucherModal}
                        message={
                            this.state.pdfRes
                        }
                        primaryButtonText={""}
                        secondaryButtonText={"Cancel"}
                        handleClose={(e) => this.setState({ TalentVoucherModal: false })}
                    />
                }
            </div>

        )
    }
}

export default withUserContext(TalentVoucher)