import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import dompurify from 'dompurify';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import { getFormattedDate } from '../../Common/Helper';
import './ShowSeason.scss';

class ContractDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    getContractCharacters = () => {
        let contractNames = [];
        this.props?.contractDetails?.characters?.map(item => {
            contractNames?.push(item.name);
        })

        return contractNames?.join(', ');
    }

    handleContractOptions = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleCloseContractOptions = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        let open = Boolean(this.state.anchorEl);
        let id = open ? 'contract-option-popover' : undefined;
        const sanitizer = dompurify.sanitize;

        return (
            <div>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer fluid className="contractDetailsContainer p-0">
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow className="d-flex">
                                            <MDBCol>
                                                <BasicLabel text={"Recording Date"} />
                                                <p>
                                                    {this.props?.contractDetails?.recording_date ?
                                                        getFormattedDate(this.props?.contractDetails?.recording_date, false) :
                                                        "-"}
                                                </p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Character"} />
                                                <p>{this.getContractCharacters() || "-"}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Contract Date"} />
                                                <p>{this.props?.contractDetails?.contract_date ?
                                                    getFormattedDate(this.props?.contractDetails?.contract_date, false) :
                                                    "-"}
                                                </p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Rate"} />
                                                <p>{this.props?.contractDetails?.daily_rate ?
                                                    `${this.props?.contractDetails?.daily_rate}/Day` : "-"}
                                                </p>
                                            </MDBCol>
                                            {/* <MDBCol>
                                                <BasicLabel text={"Per"} />
                                                <p>{this.props?.contractDetails?.per || "-"}</p>
                                            </MDBCol> */}
                                            <MDBCol>
                                                <BasicLabel text={"10th Run Buyout"} />
                                                <p>{this.props?.contractDetails?.tenth_run_buyout || "-"}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Rider"} />
                                                {this.props?.contractDetails?.is_rider ?
                                                    <p>{"Attached"}</p> :
                                                    // <p className="remove-rte-margin" dangerouslySetInnerHTML={{ __html: sanitizer(this.props?.contractDetails?.rider) }} /> :
                                                    <p>{"Not Attached"}</p>
                                                }
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Union"} />
                                                <p>{this.props?.contractDetails?.union || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="d-flex">
                                            <MDBCol>
                                                <BasicLabel text={"Sent Date"} />
                                                <p>{this.props?.contractDetails?.sent_date ?
                                                    getFormattedDate(this.props?.contractDetails?.sent_date, false) :
                                                    "-"}
                                                </p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Returned Date"} />
                                                <p>{this.props?.contractDetails?.returned_date ?
                                                    getFormattedDate(this.props?.contractDetails?.returned_date, false) :
                                                    "-"}
                                                </p>
                                            </MDBCol>
                                            <MDBCol md={2} className="e-sign-btn">
                                                <BasicButton
                                                    variant="contained"
                                                    text={'E-Sign Manager'}
                                                    onClick={() => this.setState({ openESignManagerModal: true })}
                                                />
                                            </MDBCol>
                                            <MDBCol className="status">
                                                <p>{(this.props.contractDetails?.contract_adobe_status === 'UNSIGNED') ? "Sent for Signing" :
                                                    (this.props.contractDetails?.contract_adobe_status === 'SIGNED') ?
                                                        this.props.contractDetails?.contract_adobe_status : 'Not Started'}
                                                </p>
                                            </MDBCol>
                                            <MDBCol className="contract-opt-btn">
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={this.handleContractOptions}
                                                    endIcon={<MDBIcon icon="eye" size="small" />}
                                                >
                                                    {"Contract Options"}
                                                </Button>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={this.state.anchorEl}
                                                    onClose={this.handleCloseContractOptions}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MDBRow className="contract-options">
                                                        <MDBCol>
                                                            <div>
                                                                <BasicLabel text="Send Check To" />
                                                                <p>{this.props?.contractDetails?.send_check_to || '-'}</p>
                                                                <p className="pre-line">{this.props?.contractDetails?.send_check_to_address || '-'}</p>
                                                            </div>
                                                            <div className="agency-address">
                                                                <BasicLabel text="Agency Address (to print on contract)" />
                                                                <p className="pre-line">{this.props?.contractDetails?.agency_address_on_contract || '-'}</p>
                                                            </div>
                                                            <div>
                                                                <BasicLabel text="Representative Email (to print on contract)" />
                                                                <p>{this.props?.contractDetails?.rep_email_on_contract || '-'}</p>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol>
                                                            <BasicLabel text="Send Contract To" />
                                                            <p>{this.props?.contractDetails?.send_contract_to || '-'}</p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </Popover>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"SAG Status"} />
                                                <p>{this.props?.performerDetails?.sag_status || "-"}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"I9 Status"} />
                                                <p>{this.props?.performerDetails?.i9_status || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicLabel text={"Compensation"} />
                                                <p className='Compensation'>{this.props?.contractDetails?.compensation || "-"}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Miscellaneous"} />
                                                <p>{this.props?.contractDetails?.miscellaneous || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"Contract Type"} />
                                                <p>{this.props?.contractDetails?.contract_type || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                        <MDBCol md={3}>
                                                <BasicLabel text={"Billing Notes"} />
                                                <p>{this.props?.contractDetails?.billing_notes || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={5}>
                                                <BasicLabel text={"Contract Notes (does not go on contract)"} />
                                                <p>{this.props?.contractDetails?.notes || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="edit-contract-btn-1">
                                            {this.props.contractDetails?.contract_adobe_status==='UNSIGNED' && 
                                            <BasicButton
                                                variant="contained"
                                                text={this.props.isCancellingContract ?
                                                    <CircularProgress color="inherit" size={18} /> : "Cancel Contract"
                                                }
                                                onClick={()=>this.props.handleGenerateReport('cancel-contract')}
                                            />}
                                            </MDBCol>
                                            <MDBCol md={2} style={{paddingLeft: '0px'}} className="edit-contract-btn-1">
                                                <BasicButton
                                                    variant="contained"
                                                    text={'Delete Contract'}
                                                    onClick={this.props.displayDeletePopup}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                            {this.state.openESignManagerModal && (
                                <MainViewModalComponent
                                    open={this.state.openESignManagerModal}
                                    handleClose={() => this.setState({ openESignManagerModal: false })}
                                    headerText={"E-Sign Manager"}
                                    mode={"New"}
                                    modalName={"ESignManager"}
                                    performerDetails={this.props?.performerDetails}
                                    contractDetails={this.props?.contractDetails}
                                    seasonDetails={this.props?.seasonDetails}
                                    showId={this.props?.showId}
                                    showType={this.props?.showType}
                                    showName={this.props?.showName}
                                    showSeasonId={this.props?.showSeasonId}
                                    seasonName={this.props?.seasonName}
                                    episodeId={this.props?.episodeId}
                                    episodeName={this.props?.episodeName}
                                    performerId={this.props?.performerId}
                                    contractId={this.props?.contractId}
                                    toggleRefresh={this.props?.toggleRefresh}
                                    signerDetails={this.props?.signerDetails}
                                />
                            )}
                        </MDBContainer>
                    )
                }
            </div>
        )
    }
}

export default ContractDetails;